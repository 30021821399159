import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { PanelType } from "app/+mod/panels";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { EntityService } from "rl-common/services/entity/entity.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { IDUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class GoEntityGuard {

	public constructor(
		private readonly _router: Router,
		private readonly _entityService: EntityService,
		private readonly _linkHelper: LinkHelperService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.routeToModDetail(route.params.entityId, [], route.params.activePanel);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.routeToModDetail(route.params.entityId, [], route.params.activePanel);
	}

	private routeToModDetail(entityId: string, traversed = [], suffix?: PanelType) {
		traversed.push(entityId);

		if (traversed.length >= 5) {
			throw Error(`Too many entities traversed! ${traversed.join(" => ")}`);
		}

		const parsedEntityId = IDUtil.splitEntityID(entityId);
		if (parsedEntityId.recID < 0) {
			console.warn(`Unable to navigate to entity ${entityId}! Redirecting to home.`);
			this._router.navigate(this._linkHelper.home());
		}

		if (ConstUtils.isModuleLevelCharType(parsedEntityId.charTypeID)) {
			let modDetailsUrl = `/mod${parsedEntityId.charTypeID}/${parsedEntityId.recID}`;
			if (suffix) {
				modDetailsUrl += "/" + suffix;
			}
			return this._router.navigateByUrl(modDetailsUrl);
		}

		if (ConstUtils.isComponentLevelCharType(parsedEntityId.charTypeID)) {
			const panelSuffix = suffix ?? getRouteSuffix(parsedEntityId.charTypeID);
			return this._entityService.getParentEntityId(entityId).pipe(
				switchMap(parentEntityId => this.routeToModDetail(parentEntityId, traversed, panelSuffix))
			);
		}

		throw Error(`entityId: ${entityId} is not a module or component level char type.`);
	}
}

const charTypePanelLookup: { [key in CharTypeId]?: PanelType } = {
	[CharTypeId.User]: PanelType.Contacts,
	[CharTypeId.Property]: PanelType.Catalog,
	[CharTypeId.Usage]: PanelType.Tables,
	[CharTypeId.Right]: PanelType.Rights,
	[CharTypeId.Project]: PanelType.Projects,
	[CharTypeId.Transaction]: PanelType.Deals,
	[CharTypeId.Inventory]: PanelType.Inventory,
	[CharTypeId.Job]: PanelType.Jobs,
	[CharTypeId.Royalty]: PanelType.Royalties,
	[CharTypeId.Invoice]: PanelType.Accounting,
	[CharTypeId.Document]: PanelType.Files,
};

export function getRouteSuffix(charTypeId: CharTypeId) {
	const suffix = charTypePanelLookup[charTypeId];
	if (!suffix) {
		throw new Error(`CharType ${CharTypeIdUtil.toDisplayName(charTypeId)} not supported.`);
	}
	return suffix;
}
